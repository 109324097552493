import React from 'react'

function StudentHeader({ setIsAdding }) {
    return (
        <header>
            <h2>Student Attendance List </h2>            
        </header>
    )
}

export default StudentHeader