
import React from 'react';
import './AnnualEvent.css';
import Navbar from '../Navbar';

function AnnualEvent() {
  return (
    <>
        <Navbar />
        <video src='https://s3.amazonaws.com/flytoez.content/TRAILER3_VER2.mp4' autoPlay loop />
        {/* <form className="form">
            <div><h2> Annual Event September 24th 2023</h2>
                        
                        <div className="username">
                        Don't miss the biggest dance extravaganza of the year! 🌟 Location: Ipswich corn Exchange Theatre - King St, Ipswich IP1 1DH
Time: 5:30 pm onwards
Introducing the Flytoez Dance Company Annual Event, happening at the incredible Ipswich Corn Exchange Theatre. Join us on Sunday 24th September 2023 at 5:30 pm onwards for a night filled with jaw-dropping performances and unforgettable moments!
🎟 Calling all students! Your entry is FREE! This is YOUR time to shine on stage and showcase your incredible talent. For everyone else, get your tickets now and be prepared to be blown away!
Tickets for Adults and Children above 5 years is £13 only. Tickets link below.
Link
🌠 Why Ipswich Corn Exchange Theatre, you ask? Because this is the perfect platform for our students to perform in front of a massive audience. We're expecting a packed house, so don't miss the opportunity to witness the magic unfold right before your eyes.
Performances:
💃 Get ready to experience dance forms and styles that will leave you speechless
• Elastic Band Dance
• Contemporary
• Salsa
• Dance LED lights
• Mirror Dance
• Reflection Dance
• Kids Cinematic Dance
• Special (God Gifted) kid’s performance with Nessa - Charity
• LIVE Music by Demin (https://www.youtube.com/watch?v=5sk8Tr_jEoo)
Awards:
🏆* The excitement doesn't stop there! Be a part of the FDC Awards and witness extraordinary talent being recognized. *
• Best Dancer of FDC 2023 - FDC Pendant – 22 crt GOLD
• Most punctual to the class of the year 2023 - Certificate + Medal + Cash voucher
• Passionate warmups and most flexible person of the year 2023 - Certificate +
Medal + Cash voucher
• Best Parent of the year who supports FDC in all the ways - Medal + Cash
voucher.
• Best performers in each group - Certificate + Medal + Cash voucher
• All students will receive - Medal + Certificate.
👏 We need YOUR support! Come together, encourage our talented students, and celebrate their achievements. Don't wait, book your tickets ASAP to avoid any last- minute disappointments. This is a show you simply cannot miss!
🎉 Join us and witness a night of dance extravaganza at the Flytoez Dance Company
Annual Event! 🤩
Thanks,
Flytoez Dance Company
                        </div>
                       
                    </div>
        </form> */}
    </>
  );
}

export default AnnualEvent;