
import React from 'react';
import '../../App.css';
import PromotionSection from '../PromotionSection';

function Home() {
  return (
    <>
      <PromotionSection />
    </>
  );
}

export default Home;