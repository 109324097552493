import React from "react";

function AboutUs() {
   
      return (
        <>
            <div >
            <b>FLYTOEZ Dance Company</b> is based in Ipswich, Suffolk, United Kingdom <br/>
            tasked with bringing the artistic creations of Nesa to life. He is a professional dancer from South India, Professionally Learned dance from Flyerz Dance company since 2008 and was running a corporate dance company called Tapping Toez since 2008, also have been a back stage dancers in South Indian TV shows.
            <br/><br/>
            <b>Industry-Leading Dance Company</b>
            <br/><br/>
            </div>
        </>
      );

    
}

export default AboutUs;